/* eslint-disable */
import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import EmployeeSmiling from '../../images/people/employee-smiling.jpg'
import EmployeeSmilingMobile from '../../images/people/employee-smiling-mobile.jpg'

import {
  Billboard,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Form } from '@leshen/ui'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'

import footerData from '../../data/footerData'
import headerData from '../../data/headerData'

import axios from 'axios'

const Home = () => {
  const apiKey = process.env.GATSBY_SENDGRID

  const [showSubmissionMessage, setShowSubmissionMessage] = useState(false)

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Contact Us | Clearlink',
          description:
            'Contact SocialX to learn more about our social media marketing services.',
          canonical: 'https://clearlink.com/contact',
          robots: 'follow,index',
        },
        path: '/socialx/contact',
        hideStickyCTA: false,
        spanish: false,
        sections: [],
      },
      site: {
        siteMetadata: {
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'SocialX | Contact Us',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <Billboard
          backgroundColor="primaryalt"
          alignImageToBottom
          className="content-transparent-desktop mobile-image-full"
          variant="full"
          image={
            <img
              src={EmployeeSmiling}
              alt="employees working in an office environment"
            />
          }
          mobileImage={
            <img
              src={EmployeeSmilingMobile}
              alt="employees working in an office environment"
            />
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h1">Let's Connect</Typography>
              <Typography variant="h4" style={{ marginBottom: '26px' }}>
                Got a question? We'd love to hear from you. Send us a message
                and we'll respond as soon as possible.
              </Typography>
            </div>
          }
        />
        <VariableContent>
          <Form
            formId={null}
            formSource="eloqua"
            submitButtonText="Submit"
            submitButtonColor="primary"
            submitButtonOutlined={false}
            submitButtonGradient={false}
            inlineButton={false}
            formName={null}
            onSubmit={async ({
              values,
              setShowSubmissionMessage,
              setShowSubmissionErrorMessage,
            }) => {
              const params = new URLSearchParams({
                name: values['name'],
                email: values['email'],
                phone_number: values['phone_number'],
                company: values['company'],
                interested_in: values['interested_in']?.displayName,
                message: values['message'],
                form_type: 'Clearlink',
                email_type: 'Internal',
                email_provider: 'SendGrid',
              })

              try {
                const response = await axios.post(
                  `https://production-customer-communication.clearlinkdata.com/v1/email/send_email?${params.toString()}`,
                  {},
                  {
                    headers: {
                      'x-api-key': apiKey,
                      'Content-Type': 'application/json',
                    },
                  }
                )
                if (response.status === 202) {
                  setShowSubmissionMessage(true) // Setting showSubmissionMessage to true here
                } else {
                  setShowSubmissionMessage(false)
                }
              } catch (error) {
                console.error('Submission error:', error)
                setShowSubmissionMessage(false)
                setShowSubmissionErrorMessage(true)
              }
            }}
            emilyTCPAUpdate={() => {}}
            steps={null}
            showSubmissionMessage={showSubmissionMessage}
            fields={[
              { name: 'name', type: 'text', label: 'Name' },
              { name: 'email', type: 'text', label: 'Email Address' },
              { name: 'phone_number', type: 'text', label: 'Phone Number' },
              { name: 'company', type: 'text', label: 'Company' },
              {
                name: 'interested_in',
                type: 'singleSelect',
                label: 'Interested In',
                options: [
                  {
                    name: 'employment',
                    value: 'employment',
                    displayName: 'Employment',
                    label: 'Employment',
                  },
                  {
                    name: 'services',
                    value: 'services',
                    displayName: 'Services',
                    label: 'Services',
                  },
                  {
                    name: 'media/PR',
                    value: 'mediaPr',
                    displayName: 'Media/PR',
                    label: 'Media/PR',
                  },
                  {
                    name: 'affiliate',
                    value: 'affiliate',
                    displayName: 'Affiliate',
                    label: 'Affiliate',
                  },
                  {
                    name: 'other',
                    value: 'other',
                    displayName: 'Other',
                    label: 'Other',
                  },
                ],
              },
              { name: 'message', type: 'text', label: 'Message' },
            ]}
            isMultiStep={false}
            heading={'Send Us a Message'}
            subheading={null}
            tcpaCheckbox={false}
            formDisclaimerPosition="Below Submit Button"
            disclaimer={null}
            initialStepValues={{}}
            smartyStreetsWebsiteKey={null}
            addressQualificationCode={null}
            spanish={false}
            requestId={null}
          />
        </VariableContent>
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default Home
